
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'button',
    },
    link: {
      type: [String, Object],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    primaryButton: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    buttonStyle() {
      let classes

      if (this.disabled) {
        classes = 'cursor-not-allowed text-white bg-gray-400'
        return classes
      }

      if (this.primaryButton) {
        classes = 'text-white bg-dark-green md:hover:bg-light-green'
      } else
        classes =
          'text-dark-green md:hover:text-light-green bg-white border border-dark-green md:hover:border-light-green'

      return classes
    },
  },
}


export default {
  data() {
    return {
      navOpen: false,
    }
  },
  watch: {
    '$route.path': {
      handler: async function (path) {
        if (path === '/') {
          await this.$nextTick()
          if (this.$refs.navigation) {
            const navigation = this.$refs.navigation.$el
            const nodes = Array.from(navigation.querySelectorAll('a'))
            nodes.forEach((node) => {
              if (node.innerText === 'NASLOVNICA') {
                node.classList.add('nuxt-link-active')
              }
            })
          }
        } else {
          if (this.$refs.navigation) {
            const navigation = this.$refs.navigation.$el
            const nodes = Array.from(navigation.querySelectorAll('a'))
            nodes.forEach((node) => {
              if (node.innerText === 'NASLOVNICA') {
                node.classList.remove('nuxt-link-active')
              }
            })
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    toggleNav() {
      this.navOpen = !this.navOpen
      if (this.navOpen === true) {
        document.body.style.overflowY = 'hidden'
      } else document.body.style.overflowY = 'auto'
    },
    closeNav() {
      this.navOpen = false
      document.body.style.overflowY = 'auto'
    },
  },
}

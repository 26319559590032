
export default {
  data() {
    return {
      privacyPage: false,
    }
  },
  mounted() {
    if (
      $nuxt.$route.path === '/pravila-privatnosti' ||
      $nuxt.$route.path === '/privacy-policy'
    ) {
      this.privacyPage = true
    }
  },
}

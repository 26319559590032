// Types
import mixins from"vue-typed-mixins";import{Page}from"@gauss/cms-shared";// Enums
import{Terms}from"../../enums/Terms";// Mixins
import Paginatable from"../../mixins/Paginatable";import{WithPartialInject}from"../../utils/withInject";import ProductListProvider from"../../mixins/ProductListProvider";import ProductFilterFieldSelector from"../../mixins/ProductFilterFieldSelector";import Debouncable from"../../mixins/Debouncable";const SearchInject=WithPartialInject("isInsideSearchPage"),PERFORM_SEARCH_EVENT="perform-search";function genCategories(){var a,b;return null!==(a=this.$moduleOptions)&&void 0!==a&&null!==(b=a.searchCategories)&&void 0!==b&&b.length?this.$moduleOptions.searchCategories.map(a=>({slug:a,name:this.$t("core.term."+a)})):[{slug:Terms.Pages,name:this.$t("core.term.pages")},{slug:Terms.Products,name:this.$t("core.term.products")},{slug:Terms.Posts,name:this.$t("core.term.posts")},{slug:Terms.ProductCategories,name:this.$t("core.term.productCategories")}]}// Main
const ControllerSearch=mixins(Paginatable,SearchInject,ProductListProvider,ProductFilterFieldSelector,Debouncable({items:500,query:500,activeCategory:500})).extend({name:"ControllerSearch",data(){const a=genCategories.call(this),{query:b}=this.$route,c=b.search||"",d=b.page||1,e=b.category||a[0].slug;return{categories:a,options:{page:d,activeCategory:e,keywords:c}}},created(){if(!this.computedForm.activeCategory)throw this.$t("error.missingSearchCategories")},mounted(){this.isInsideSearchPage&&this.searchPageOpened&&this.$nuxt.$on(PERFORM_SEARCH_EVENT,this.onPerformSearch)},beforeDestroy(){this.isInsideSearchPage&&this.searchPageOpened&&this.$nuxt.$off(PERFORM_SEARCH_EVENT,this.onPerformSearch)},pagination(){const{activeCategory:a}=this.computedForm;return{request:b=>this.genRequest(a,b),handleItems:b=>{a===Terms.Products?this.setProducts(b):this.setProducts([])}}},computed:{computedForm(){// eslint-disable-next-line @typescript-eslint/no-this-alias
const{options:a,status:b,refreshQueryParamsDebounced:c,fetchDataDebounced:d,debounce:e}=this;return{get page(){return a.page},set page(b){a.page===b||(// Setting page inside Paginatable results in data fetching, that line is not missing
a.page=b,c())},get search(){return a.keywords},set search(b){a.keywords===b||(// Watcher inside Paginatable resets page automatically, that line is not missing
// Setting page inside Paginatable results in data fetching, so that is not missing either
a.keywords=b,c())},get activeCategory(){return a.activeCategory},set activeCategory(f){e("activeCategory",()=>{b.pending||a.activeCategory===f||(a.activeCategory=f,a.page=1,d(),c())})}}},// ControllerSearch can be used both inside PageSearch and HeaderElement,
// that is why we need to make 2 ControllerSearch components work together on SearchPage
// which affects submit function
searchPageOpened(){var a;return(null===(a=this.$route.name)||void 0===a?void 0:a.split("_")[0])===this.$Page.Search}},methods:{setActiveCategory(a){a&&(this.computedForm.activeCategory=a.slug)},onPerformSearch({search:a,category:b,page:c}){this.options.activeCategory=b,this.options.keywords=a||"",this.options.page=c,this.fetchDataDebounced(),this.refreshQueryParamsDebounced()},genRequest(a,b){const c={[Terms.Pages]:this.fetchPages,[Terms.Products]:this.fetchProducts,[Terms.Posts]:this.fetchPosts,[Terms.ProductCategories]:this.fetchProductCategories},d=c[a];if(!d)throw"requestPerActiveCategory didn't find appropriate request";else return d(b)},fetchPages(a){const b=this.$api.cms.post.filterStandard({filters:[{key:"display",value:"components"}],...a});return b},fetchProducts(a){const b=this.$api.product.web.filter({filters:[{key:"archived",value:!1,default:!0}],select:this.computedSelectParam,...a});return b},fetchPosts(a){const b=this.$api.cms.post.filterStandard({filters:[{key:"display",value:"legacy"},{key:"postTypeSlugs",value:["post"]}],...a});return b},fetchProductCategories(a){const b=this.$api.product.web.category.filter({filters:[],sort:{param:"id",order:"asc"},tree:!0,...a});return b},refreshQueryParamsDebounced(){this.debounce("query",this.refreshQueryParams)},async refreshQueryParams(){if(!this.isInsideSearchPage)return;const{search:a,activeCategory:b,page:c}=this.computedForm,d={category:b,page:c};a&&(d.search=a);try{await this.$router.replace({// @ts-ignore
query:d})}catch(a){console.error(this.$t("error.redundantNavigationSkipped"))}},async submit(){if(this.isInsideSearchPage)return;const{search:a,activeCategory:b}=this.computedForm,c=this.options.page,d={category:b,page:c};if(a&&(d.search=a),this.searchPageOpened)this.$nuxt.$emit(PERFORM_SEARCH_EVENT,d);else try{await this.$router.push(this.localePath({name:Page.Search,// @ts-ignore
query:d}))}catch(a){console.error(this.$t("error.redundantNavigationSkipped"))}}},render(){const{computedForm:a,pageNumbers:b,categories:c,setActiveCategory:d,submit:e}=this,f={...this.paginatableSlotProps,form:a,pageNumbers:b,categories:c,setActiveCategory:d,submit:e};return this.renderContainer(f)}});export default ControllerSearch;
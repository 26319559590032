

const asyncCookieElement = () => import('../../../components/cmsOverrides/CookieElement')
const asyncErrorView = () => import('../../../components/cmsOverrides/ErrorView')
const asyncFooterElement = () => import('../../../components/cmsOverrides/FooterElement')
const asyncHeaderElement = () => import('../../../components/cmsOverrides/HeaderElement')
import CookieElement from '../../../components/cmsOverrides/CookieElement'
import ErrorView from '../../../components/cmsOverrides/ErrorView'
import FooterElement from '../../../components/cmsOverrides/FooterElement'
import HeaderElement from '../../../components/cmsOverrides/HeaderElement'

const asyncCategoryList = () => import('./shop/CategoryList')
const asyncProductList = () => import('./shop/ProductList')
const asyncPostList = () => import('./shop/PostList')
const asyncPostCategory = () => import('./shop/PostCategory')
const asyncCartView = () => import('./shop/CartView')
const asyncCheckoutView = () => import('./shop/CheckoutView')
const asyncCheckoutSuccessView = () => import('./shop/CheckoutSuccessView')
const asyncProfileView = () => import('./shop/ProfileView')
const asyncLoginView = () => import('./shop/LoginView')
const asyncRegisterView = () => import('./shop/RegisterView')
const asyncPostView = () => import('./shop/PostView')
const asyncMaintenanceView = () => import('./shop/MaintenanceView')
const asyncForgotPasswordView = () => import('./shop/ForgotPasswordView')
const asyncSearchView = () => import('./shop/SearchView')
const asyncCartModalView = () => import('./shop/CartModalView')
const asyncSocialLoginView = () => import('./shop/SocialLoginView')
const asyncProductListAll = () => import('./shop/ProductListAll')
const asyncConfirmEmailView = () => import('./shop/ConfirmEmailView')
const asyncBundleCustomizationModalView = () => import('./shop/BundleCustomizationModalView')
import CategoryList from './shop/CategoryList'
import ProductList from './shop/ProductList'
import PostList from './shop/PostList'
import PostCategory from './shop/PostCategory'
import CartView from './shop/CartView'
import CheckoutView from './shop/CheckoutView'
import CheckoutSuccessView from './shop/CheckoutSuccessView'
import ProfileView from './shop/ProfileView'
import LoginView from './shop/LoginView'
import RegisterView from './shop/RegisterView'
import PostView from './shop/PostView'
import MaintenanceView from './shop/MaintenanceView'
import ForgotPasswordView from './shop/ForgotPasswordView'
import SearchView from './shop/SearchView'
import CartModalView from './shop/CartModalView'
import SocialLoginView from './shop/SocialLoginView'
import ProductListAll from './shop/ProductListAll'
import ConfirmEmailView from './shop/ConfirmEmailView'
import BundleCustomizationModalView from './shop/BundleCustomizationModalView'

export {asyncCookieElement, CookieElement, asyncErrorView, ErrorView, asyncFooterElement, FooterElement, asyncHeaderElement, HeaderElement, asyncCategoryList, CategoryList, asyncProductList, ProductList, asyncPostList, PostList, asyncPostCategory, PostCategory, asyncCartView, CartView, asyncCheckoutView, CheckoutView, asyncCheckoutSuccessView, CheckoutSuccessView, asyncProfileView, ProfileView, asyncLoginView, LoginView, asyncRegisterView, RegisterView, asyncPostView, PostView, asyncMaintenanceView, MaintenanceView, asyncForgotPasswordView, ForgotPasswordView, asyncSearchView, SearchView, asyncCartModalView, CartModalView, asyncSocialLoginView, SocialLoginView, asyncProductListAll, ProductListAll, asyncConfirmEmailView, ConfirmEmailView, asyncBundleCustomizationModalView, BundleCustomizationModalView}

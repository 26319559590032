// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./Inter/Inter-Thin.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./Inter/Inter-ExtraLight.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./Inter/Inter-Light.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./Inter/Inter-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./Inter/Inter-Medium.ttf");
var ___CSS_LOADER_URL_IMPORT_5___ = require("./Inter/Inter-SemiBold.ttf");
var ___CSS_LOADER_URL_IMPORT_6___ = require("./Inter/Inter-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_7___ = require("./Inter/Inter-ExtraBold.ttf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Inter\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");font-weight:100;font-style:normal;font-display:swap}@font-face{font-family:\"Inter\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");font-weight:200;font-style:normal;font-display:swap}@font-face{font-family:\"Inter\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");font-weight:300;font-style:normal;font-display:swap}@font-face{font-family:\"Inter\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\");font-weight:400;font-style:normal;font-display:swap}@font-face{font-family:\"Inter\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"truetype\");font-weight:500;font-style:normal;font-display:swap}@font-face{font-family:\"Inter\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"truetype\");font-weight:600;font-style:normal;font-display:swap}@font-face{font-family:\"Inter\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"truetype\");font-weight:700;font-style:normal;font-display:swap}@font-face{font-family:\"Inter\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format(\"truetype\");font-weight:800;font-style:normal;font-display:swap}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
